body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
    font-family: "OpenSans-Semibold";
    src: url(/static/media/OpenSans-SemiBold.e1c83f94.ttf);
}
.app {
  width: 100%;
  height: 100%;
}

.content {
  /* min-height: calc(400vh - 70px); */
  margin-top: 19%;
}

body,
html,
div.board,
div#react-container {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.navAppBar {
  box-shadow: none;
  padding: 1% 17%;
  background-color: rgba(0, 0, 0, 0.500) !important;
}


@media (max-width: 1025px) {
  .navAppBar {
    padding: 1% 6%;
  }
}

.navFullList {
  width: 100%;
}

.navGrow {
  flex-grow: 1;
}

.navRoot {
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 959px) {
  .navDesktop {
    display: none;
  }

  .navMobile {
    display: flex;
  }
}

@media (min-width: 959px) {
  .navDesktop {
    display: flex;
  }

  .navMobile {
    display: none;
  }
}

.navTitle {
  left: 0;
  top: 0;
}

.toolBarZeroPadding {
  height: 82;
  left: 0;
  padding-left: 0;
  top: 0;
}

.topMenuItem {
  padding: 20;
}

.navMenu:hover {
  font-weight: 600;
  border-bottom: 2px solid #0a539d;
}

.mobileNav,
.mobileNav:active,
.mobileNav:visited {
  color: #000;
  font-family: "OpenSans-Semibold";
  text-transform: uppercase;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  text-align: center;
}

.divDrawer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

button.dropBtn{
  cursor: pointer;
}

.desktopNav,
.dropBtn {
  color: #fff;
  font-family: "OpenSans-Semibold";
  font-size: 16px;
  text-transform: uppercase;
  padding: 15px;
  text-decoration: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none; /*2px solid #fff*/
  outline: none;
  background-color: inherit;
}

.desktopNav:hover,
.dropBtn:hover {
  /* border-bottom: 2px solid #0a539d; */
  text-shadow: 0 0 5px #006eff; 
}

.dropdownContent {
  display: none;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.226);
  z-index: 5;
  min-width: 175px;
  padding: 5px;
  border-radius: 2px;
  position: absolute;
}

.dropItem {
  color: #000;
  font-family: "OpenSans-Semibold";
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
}

.dropItem > div:hover {
  font-family: "OpenSans-Bold";
}

.dropdownContent > .dropItem > div {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.dropdownContent:last-child > .dropItem:last-child > div {
  border-bottom: none;
}

.desktopDropdown:hover > .dropdownContent {
  display: block;
}

.imgGallery {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: 70vh;
}

@media (max-width: 426px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}
@media (min-width: 427px) and (max-width: 650px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}

/* @media (max-width: 426px) and (min-width: 350px) {
  .image-gallery-slide img {
    height: 45vh !important;
    width: 100%;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}
@media (max-width: 1200px) and (min-width: 1030px) {
  .image-gallery-slide img {
    height: 35vh !important;
    width: 100%;
  }
}

@media (max-width: 2550px) and (min-width: 2100px) {
  .image-gallery-slide img {
    height: 60vh !important;
    width: 100%;
  }
} */

.intro {
  /* width: 330px;
  height: 200px; */
  width: 26.5%;
  height: 150%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
}

.edfWrapper > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(38, 76, 114);
}

.edfWrapper {
  width: 70%;
  padding-left: 20px;
  padding-top: 0.5px;
}

.edfWrapper > p {
  font-size: 20px;
}

@media (max-width: 320px){
  .intro {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}


@media (max-width: 426px) and (min-width:322px) {
  .intro {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .intro {
    width: 78%;
    height: 120%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .intro {
    width: 30%;
  height: 105%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
  }
}


.aboutbox {
  /* width: 1010px;
  height: 550px; */
  width: 60%;
  padding: 2% 2% 2% 2%;
  color: #fff;
  position: relative;
  margin-top: 1%;
  /* margin-left: 16.8%;
  margin-right: 16.8%; */
  margin: auto;
  background-image: -webkit-linear-gradient(
      rgba(3, 87, 189, 0.63),
      rgba(3, 87, 189, 0.63)
    ),
    url(/static/media/bali.a24d5b37.jpg);
  background-image: linear-gradient(
      rgba(3, 87, 189, 0.63),
      rgba(3, 87, 189, 0.63)
    ),
    url(/static/media/bali.a24d5b37.jpg);
  background-size: cover;
}

.aboutbox > h2 {
  color: rgb(255, 255, 255);
  font-weight: normal;
  width: 100%;
  margin-bottom: 2%;
}

.hrAbout {
  float: left;
  width: 40%;
  border: 1px solid #fff;
  margin: 0;
}

.aboutbox > p {
  line-height: 1.6;
}


  
  @media (max-width: 320px){
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2% 10% 2% 10%;
    }

    .hrAbout {
      float: left;
      width: 100%;
      border: 1px solid #fff;
      margin: 0;
    }
  }
  
  
  @media (max-width: 426px) and (min-width:322px) {
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2% 12% 2% 12%;
    }
    .hrAbout {
      float: left;
      width: 100%;
      border: 1px solid #fff;
      margin: 0;
    }
  }
  
  @media (max-width: 769px) and (min-width: 427px) {
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2% 14% 2% 14%;
    }
    .hrAbout {
      float: left;
      width: 100%;
      border: 1px solid #fff;
      margin: 0;
    }
  }
  
  @media (max-width: 1025px) and (min-width: 770px) {
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
    }
  }


.map {
  /* width: 1200px;
  height: 600px; */
  width: 75%;
  height: 610px;
  /* padding-bottom: 45%; */
  position: relative;
  /* margin-left: 13%;
  margin-right: 13%; */
  margin: auto;
  margin-top: 2%;
  background-image: url(/static/media/dottedmap.2cc1301d.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border: 2px solid black; */
}

.maptitle > h1 {
  text-align: center;
  font-family: "OpenSans-Semibold";
  letter-spacing: 2px;
  margin-top: 4%;
}
.maptitle > h2 {
  text-align: center;
  font-family: "OpenSans-Semibold";
  letter-spacing: 2px;
  color: rgb(66, 134, 244);
}

.mapmarker{
  display: none;
}

@media (max-width: 426px) and (min-width:322px) {
  .mapmarker{
    display: block;
  }
  .map {
    display: none;
  }
}



.marker {
  height: 26%;
  width: 20%;
  background-image: url(/static/media/singapore.5fdba3b5.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  margin-left: 79%;
  margin-top: 22%;
}

/* @media (max-width: 500px) {
  .marker {
    height: 20%;
    width: 14%;
    background-image: url(../../assets/images/singapore.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
} */

.name {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(39, 84, 148, 0.9);
}

.name > p {
  font-family: "OpenSans-Semibold";
  text-transform: uppercase;
  text-indent: 2px;
  letter-spacing: 2px;
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 0;
  padding: 8px 0;
}

.visit {
  width: 50px;
  height: 50px;
  background-color: rgb(231, 108, 45);
  position: absolute;
  margin-left: 78%;
}

.visit > p {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 15%;
  margin-left: 4%;
}

@media (max-width: 426px) and (min-width: 322px) {
  .marker {
    width: 20%;
    background-image: url(/static/media/singapore.5fdba3b5.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin: auto;
    padding:30%;
  }
  .visit {
    width: 50px;
    height: 50px;
    background-color: rgb(231, 108, 45);
    position: absolute;
    margin-left:0%;
   
  }
  .name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(39, 84, 148, 0.9);
  }
  .name > p {
    font-family: "OpenSans-Semibold";
    text-transform: uppercase;
    text-indent: 2px;
    letter-spacing: 2px;
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 0;
    padding: 8px 0;
  }
}

/* .investors {
  background-image: url(../../assets/images/investors.png);
} */

.gridWrapper {
  margin-left: 16%;
  margin-right: 16%;
  margin-top: 5%;
}

.risingTitle {
  width: 80%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  padding-bottom: 20px;
  background-image: url(/static/media/worldmap.58d1b0cc.png);
  margin: 0px auto;
  background-size: cover;
  background-position: center;
}

.risingTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
}

.updatesTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
  width: 30%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 5%;
}

.partnersTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
  width: 30%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

.partnersTitle > p {
  width: 70%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

/* .greybox {
  z-index: -1;
  height: 80%;
  width: 100%;
  background-color: rgb(248, 248, 248);
}

.greybox1 {
  position: absolute;
  z-index: -1;
  height: 1400px;
  width: 100%;
  background-color: rgb(248, 248, 248);
  margin-top: 10%;
} */

.IntroGrid {
  padding-left: 18%;
  margin-top: 18%;
  margin-bottom: 5%;
  width: auto;
}

@media (max-width: 321px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 50%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 376px) and (min-width: 350px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 40%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 426px) and (min-width: 380px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 37%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 50%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .IntroGrid {
    position: relative;
    width: auto;
    margin-top: 23%;
    max-width: 82%;
  }
}
@media (max-width: 1200px) and (min-width: 1030px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 24%;
  }
}

@media (max-width: 2550px) and (min-width: 2100px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 35%;
  }
}

.updatesWrapper {
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

.content > h2 {
  color: rgb(36, 81, 151);
  font-family: "OpenSans-Semibold";
}
.content {
  margin: auto;
}

.partnersWrapper {
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

.Footer {
  /* width: 100%;
  height: 50px;*/
  background-color: #333;
  position: relative;
  bottom: 0;
  margin-top: 5%;
  width: 100%;
  height: 60px; /* Height of the footer */
}

.introSG {
  /* width: 330px;
  height: 200px; */
  width: 26.5%;
  height: 150%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
}

@media (max-width: 320px){
  .introSG {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}


@media (max-width: 426px) and (min-width:322px) {
  .introSG {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .introSG {
    width: 78%;
    height: 120%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .introSG {
    width: 30%;
  height: 105%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
  }
}

.sgWrapper > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(38, 76, 114);
}

.sgWrapper {
  width: 70%;
  padding-left: 20px;
  padding-top: 0.5px;
}

.toBK {
  width: 130px;
  height: 130px;
  background-color: rgb(231, 108, 45);
  position: absolute;
  left: 73.5%;
  bottom: 19.5%;
}

@media (max-width: 769px) and (min-width: 427px) {
  .toBK {
    width: 130px;
    height: 130px;
    background-color: rgb(231, 108, 45);
    position: absolute;
    left: 66%;
    bottom: 22.5%;
  }
}

@media (max-width: 426px) and (min-width: 350px) {
  .toBK {
    width: 250px;
    height: 130px;
    background-color: rgb(231, 108, 45);
    position: relative;
    left: 20%;
  }
}

.toBK > p {
  color: white;
  text-align: center;
  font-size: 23px;
}

.gridWrapper3 {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 2%;
}

.newsTitle {
  /* width: 80%; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  padding-bottom: 57px;
  margin: 0px auto;
  background-size: cover;
  background-position: center;
  background-color: rgba(229, 229, 229, 0.9);
}

.newsTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
}

.imgGallerysg {
  position: relative;
  width: 66.5%;
  margin-left: 16.8%;
  margin-right: 16.8%;
}

.image-gallery-slide img {
  width: 100%;
  height: 70vh;
}

.galleryTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
  width: 30%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

.intro1 {
  width: 26.5%;
  height: 150%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
}

@media (max-width: 320px){
  .intro1 {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}


@media (max-width: 426px) and (min-width:322px) {
  .intro1 {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .intro1 {
    width: 78%;
    height: 120%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .intro1 {
    width: 30%;
  height: 105%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
  }
}

.contactWrapper > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(38, 76, 114);
}

.contactWrapper {
  width: 70%;
  padding-left: 20px;
  padding-top: 0.5px;
}

.gridWrapper1 {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 2%;
}

.eplTitle {
  width: 83%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  padding-bottom: 67px;
}

.eplTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
}

