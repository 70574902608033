body,
html,
div.board,
div#react-container {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

.navAppBar {
  box-shadow: none;
  padding: 1% 17%;
  background-color: rgba(0, 0, 0, 0.500) !important;
}


@media (max-width: 1025px) {
  .navAppBar {
    padding: 1% 6%;
  }
}

.navFullList {
  width: 100%;
}

.navGrow {
  flex-grow: 1;
}

.navRoot {
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 959px) {
  .navDesktop {
    display: none;
  }

  .navMobile {
    display: flex;
  }
}

@media (min-width: 959px) {
  .navDesktop {
    display: flex;
  }

  .navMobile {
    display: none;
  }
}

.navTitle {
  left: 0;
  top: 0;
}

.toolBarZeroPadding {
  height: 82;
  left: 0;
  padding-left: 0;
  top: 0;
}

.topMenuItem {
  padding: 20;
}

.navMenu:hover {
  font-weight: 600;
  border-bottom: 2px solid #0a539d;
}

.mobileNav,
.mobileNav:active,
.mobileNav:visited {
  color: #000;
  font-family: "OpenSans-Semibold";
  text-transform: uppercase;
  margin: 10px;
  padding: 10px;
  text-decoration: none;
  text-align: center;
}

.divDrawer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

button.dropBtn{
  cursor: pointer;
}

.desktopNav,
.dropBtn {
  color: #fff;
  font-family: "OpenSans-Semibold";
  font-size: 16px;
  text-transform: uppercase;
  padding: 15px;
  text-decoration: none;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none; /*2px solid #fff*/
  outline: none;
  background-color: inherit;
}

.desktopNav:hover,
.dropBtn:hover {
  /* border-bottom: 2px solid #0a539d; */
  text-shadow: 0 0 5px #006eff; 
}

.dropdownContent {
  display: none;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.226);
  z-index: 5;
  min-width: 175px;
  padding: 5px;
  border-radius: 2px;
  position: absolute;
}

.dropItem {
  color: #000;
  font-family: "OpenSans-Semibold";
  font-size: 16px;
  text-transform: uppercase;
  text-decoration: none;
}

.dropItem > div:hover {
  font-family: "OpenSans-Bold";
}

.dropdownContent > .dropItem > div {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.dropdownContent:last-child > .dropItem:last-child > div {
  border-bottom: none;
}

.desktopDropdown:hover > .dropdownContent {
  display: block;
}
