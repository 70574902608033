/* .investors {
  background-image: url(../../assets/images/investors.png);
} */

.gridWrapper {
  margin-left: 16%;
  margin-right: 16%;
  margin-top: 5%;
}

.risingTitle {
  width: 80%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  padding-bottom: 20px;
  background-image: url(../../assets/images/worldmap.png);
  margin: 0px auto;
  background-size: cover;
  background-position: center;
}

.risingTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
}

.updatesTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
  width: 30%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 5%;
}

.partnersTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
  width: 30%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

.partnersTitle > p {
  width: 70%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

/* .greybox {
  z-index: -1;
  height: 80%;
  width: 100%;
  background-color: rgb(248, 248, 248);
}

.greybox1 {
  position: absolute;
  z-index: -1;
  height: 1400px;
  width: 100%;
  background-color: rgb(248, 248, 248);
  margin-top: 10%;
} */

.IntroGrid {
  padding-left: 18%;
  margin-top: 18%;
  margin-bottom: 5%;
  width: auto;
}

@media (max-width: 321px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 50%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 376px) and (min-width: 350px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 40%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 426px) and (min-width: 380px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 37%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 50%;
    /* margin-left: 0%;
    margin-right: 0%; */
    max-width: 82%;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .IntroGrid {
    position: relative;
    width: auto;
    margin-top: 23%;
    max-width: 82%;
  }
}
@media (max-width: 1200px) and (min-width: 1030px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 24%;
  }
}

@media (max-width: 2550px) and (min-width: 2100px) {
  .IntroGrid {
    position: relative;
    width: 100%;
    margin-top: 35%;
  }
}
