.Footer {
  /* width: 100%;
  height: 50px;*/
  background-color: #333;
  position: relative;
  bottom: 0;
  margin-top: 5%;
  width: 100%;
  height: 60px; /* Height of the footer */
}
