.aboutbox {
  /* width: 1010px;
  height: 550px; */
  width: 60%;
  padding: 2% 2% 2% 2%;
  color: #fff;
  position: relative;
  margin-top: 1%;
  /* margin-left: 16.8%;
  margin-right: 16.8%; */
  margin: auto;
  background-image: linear-gradient(
      rgba(3, 87, 189, 0.63),
      rgba(3, 87, 189, 0.63)
    ),
    url(../../assets/images/bali.jpg);
  background-size: cover;
}

.aboutbox > h2 {
  color: rgb(255, 255, 255);
  font-weight: normal;
  width: 100%;
  margin-bottom: 2%;
}

.hrAbout {
  float: left;
  width: 40%;
  border: 1px solid #fff;
  margin: 0;
}

.aboutbox > p {
  line-height: 1.6;
}


  
  @media (max-width: 320px){
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2% 10% 2% 10%;
    }

    .hrAbout {
      float: left;
      width: 100%;
      border: 1px solid #fff;
      margin: 0;
    }
  }
  
  
  @media (max-width: 426px) and (min-width:322px) {
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2% 12% 2% 12%;
    }
    .hrAbout {
      float: left;
      width: 100%;
      border: 1px solid #fff;
      margin: 0;
    }
  }
  
  @media (max-width: 769px) and (min-width: 427px) {
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
      padding: 2% 14% 2% 14%;
    }
    .hrAbout {
      float: left;
      width: 100%;
      border: 1px solid #fff;
      margin: 0;
    }
  }
  
  @media (max-width: 1025px) and (min-width: 770px) {
    .aboutbox {
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

