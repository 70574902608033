.intro1 {
  width: 26.5%;
  height: 150%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
}

@media (max-width: 320px){
  .intro1 {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}


@media (max-width: 426px) and (min-width:322px) {
  .intro1 {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .intro1 {
    width: 78%;
    height: 120%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .intro1 {
    width: 30%;
  height: 105%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
  }
}

.contactWrapper > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(38, 76, 114);
}

.contactWrapper {
  width: 70%;
  padding-left: 20px;
  padding-top: 0.5px;
}

.gridWrapper1 {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 2%;
}

.eplTitle {
  width: 83%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  padding-bottom: 67px;
}

.eplTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
}
