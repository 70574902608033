.imgGallery {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
  height: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: 70vh;
}

@media (max-width: 426px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}
@media (min-width: 427px) and (max-width: 650px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}

/* @media (max-width: 426px) and (min-width: 350px) {
  .image-gallery-slide img {
    height: 45vh !important;
    width: 100%;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .image-gallery-slide img {
    height: 33vh !important;
    width: 100%;
  }
}
@media (max-width: 1200px) and (min-width: 1030px) {
  .image-gallery-slide img {
    height: 35vh !important;
    width: 100%;
  }
}

@media (max-width: 2550px) and (min-width: 2100px) {
  .image-gallery-slide img {
    height: 60vh !important;
    width: 100%;
  }
} */
