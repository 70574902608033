.intro {
  /* width: 330px;
  height: 200px; */
  width: 26.5%;
  height: 150%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
}

.edfWrapper > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(38, 76, 114);
}

.edfWrapper {
  width: 70%;
  padding-left: 20px;
  padding-top: 0.5px;
}

.edfWrapper > p {
  font-size: 20px;
}

@media (max-width: 320px){
  .intro {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}


@media (max-width: 426px) and (min-width:322px) {
  .intro {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .intro {
    width: 78%;
    height: 120%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .intro {
    width: 30%;
  height: 105%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
  }
}

