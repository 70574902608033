.introSG {
  /* width: 330px;
  height: 200px; */
  width: 26.5%;
  height: 150%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
}

@media (max-width: 320px){
  .introSG {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}


@media (max-width: 426px) and (min-width:322px) {
  .introSG {
    width: 78%;
    height: 105%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 769px) and (min-width: 427px) {
  .introSG {
    width: 78%;
    height: 120%;
    background-color: rgba(229, 229, 229, 0.9);
    position: relative;
  }
}

@media (max-width: 1025px) and (min-width: 770px) {
  .introSG {
    width: 30%;
  height: 105%;
  background-color: rgba(229, 229, 229, 0.9);
  position: relative;
  }
}

.sgWrapper > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(38, 76, 114);
}

.sgWrapper {
  width: 70%;
  padding-left: 20px;
  padding-top: 0.5px;
}

.toBK {
  width: 130px;
  height: 130px;
  background-color: rgb(231, 108, 45);
  position: absolute;
  left: 73.5%;
  bottom: 19.5%;
}

@media (max-width: 769px) and (min-width: 427px) {
  .toBK {
    width: 130px;
    height: 130px;
    background-color: rgb(231, 108, 45);
    position: absolute;
    left: 66%;
    bottom: 22.5%;
  }
}

@media (max-width: 426px) and (min-width: 350px) {
  .toBK {
    width: 250px;
    height: 130px;
    background-color: rgb(231, 108, 45);
    position: relative;
    left: 20%;
  }
}

.toBK > p {
  color: white;
  text-align: center;
  font-size: 23px;
}

.gridWrapper3 {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 2%;
}

.newsTitle {
  /* width: 80%; */
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0.5px;
  padding-bottom: 57px;
  margin: 0px auto;
  background-size: cover;
  background-position: center;
  background-color: rgba(229, 229, 229, 0.9);
}

.newsTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
}

.imgGallerysg {
  position: relative;
  width: 66.5%;
  margin-left: 16.8%;
  margin-right: 16.8%;
}

.image-gallery-slide img {
  width: 100%;
  height: 70vh;
}

.galleryTitle > h1 {
  color: rgb(38, 76, 114);
  font-weight: normal;
  border-bottom: 1.5px solid rgba(61, 167, 207);
  width: 30%;
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}
