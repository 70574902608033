.map {
  /* width: 1200px;
  height: 600px; */
  width: 75%;
  height: 610px;
  /* padding-bottom: 45%; */
  position: relative;
  /* margin-left: 13%;
  margin-right: 13%; */
  margin: auto;
  margin-top: 2%;
  background-image: url(../../assets/images/dottedmap.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  /* border: 2px solid black; */
}

.maptitle > h1 {
  text-align: center;
  font-family: "OpenSans-Semibold";
  letter-spacing: 2px;
  margin-top: 4%;
}
.maptitle > h2 {
  text-align: center;
  font-family: "OpenSans-Semibold";
  letter-spacing: 2px;
  color: rgb(66, 134, 244);
}

.mapmarker{
  display: none;
}

@media (max-width: 426px) and (min-width:322px) {
  .mapmarker{
    display: block;
  }
  .map {
    display: none;
  }
}


