.updatesWrapper {
  margin-left: 16.8%;
  margin-right: 16.8%;
  margin-top: 2%;
}

.content > h2 {
  color: rgb(36, 81, 151);
  font-family: "OpenSans-Semibold";
}
.content {
  margin: auto;
}
