@import url(./fonts.css);

.app {
  width: 100%;
  height: 100%;
}

.content {
  /* min-height: calc(400vh - 70px); */
  margin-top: 19%;
}
