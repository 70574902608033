.marker {
  height: 26%;
  width: 20%;
  background-image: url(../../assets/images/singapore.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  margin-left: 79%;
  margin-top: 22%;
}

/* @media (max-width: 500px) {
  .marker {
    height: 20%;
    width: 14%;
    background-image: url(../../assets/images/singapore.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
} */

.name {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(39, 84, 148, 0.9);
}

.name > p {
  font-family: "OpenSans-Semibold";
  text-transform: uppercase;
  text-indent: 2px;
  letter-spacing: 2px;
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 0;
  padding: 8px 0;
}

.visit {
  width: 50px;
  height: 50px;
  background-color: rgb(231, 108, 45);
  position: absolute;
  margin-left: 78%;
}

.visit > p {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 15%;
  margin-left: 4%;
}

@media (max-width: 426px) and (min-width: 322px) {
  .marker {
    width: 20%;
    background-image: url(../../assets/images/singapore.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    margin: auto;
    padding:30%;
  }
  .visit {
    width: 50px;
    height: 50px;
    background-color: rgb(231, 108, 45);
    position: absolute;
    margin-left:0%;
   
  }
  .name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(39, 84, 148, 0.9);
  }
  .name > p {
    font-family: "OpenSans-Semibold";
    text-transform: uppercase;
    text-indent: 2px;
    letter-spacing: 2px;
    font-size: 16px;
    color: white;
    text-align: center;
    margin: 0;
    padding: 8px 0;
  }
}
